<template>
    <Container>
        <adsense />
        <div class=" items-center flex rounded-md p-3 mb-3 h-32">
            <div class="text-center m-auto">
                <h1 class="text-white font-thin text-3xl md:text-5xl">Just added</h1>
                <p class="block text-white font-thin">new to Nomad Lyrics</p>
            </div>
        </div>
        <ul v-if="justadded" class="grid gap-3">
            <li v-for="(song, index) in justadded" :key="song.id">
                <JustAdded :song="song" :jumbo="index == 0" />
            </li>
        </ul>
    </Container>
</template>
<script>
import { defineAsyncComponent } from 'vue'
import { Link, usePage } from "@inertiajs/vue3";
import { mapState, mapActions, mapGetters } from "vuex";
import JustAdded from "@/components/JustAdded.vue";
export default {
    created(){
        this.getAllJustAdded();
    },
    methods: {
        ...mapActions("justadded", ["getAllJustAdded"]),
    },
    computed: {
        ...mapState({ justadded: (state) => state.justadded.all }),
        ...mapState({ justadded: (state) => state.justadded.all }),
    },
    components: {
        JustAdded,
        'adsense' : defineAsyncComponent(() => import("@/components/Adsense.vue")),
    },
    beforeMount() {
        document.title = "Heeso Cusub 2023"
    },
}
</script>