<template>
    <div>
        <ads />
        <div v-if="isLoading">...</div>
        <div v-else>
            <div class="w-full bg-nomad-900 bg-no-repeat bg-cover bg-center mt-3" :style="{ backgroundImage: 'url(' + album.cover + ')' }">
                <div class="backdrop-blur-md bg-nomad-900/50 flex items-center gap-3">
                    <div><img :src="album.cover" class="max-h-64"  loading="lazy" :alt="album.name"></div>
                    <div class="flex flex-col gap-1">
                        <span class="font-thin text-nomad-100">{{ album.content_type }}</span>
                        <h1 class="text-left text-xl lg:text-5xl font-bold text-white uppercase">{{ album.name }}</h1>
                        <h6 class="text-nomad-100">{{ album.artist.name }} </h6>
                        <h6 class="text-nomad-300">
                            <ul class="flex gap-3 list-disc list-inside">
                                <li class="list-none">{{ album.songs.length  }} songs</li>
                                <li v-if="album.released_at">{{ album.released_at }}</li>
                            </ul>
                        </h6>

                    </div>
                </div>
            </div>
            <div class="my-3">
                <h2 class="text-white text-xl font-thin mb-3 text-nomad-200">
                    Songs
                </h2>
                <div
                    class="w-full grid grid-cols-1 gap-4"
                    v-if="album.songs"
                >
                    <div v-for="song in album.songs" :key="song.id">
                        <card-song :song="song" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { usePage } from "@inertiajs/vue3";
import CardSong from '@/components/CardSong.vue'
export default {
    created() {
        const id = usePage().props.query.id;
        this.getSignleAlbum(id);
    },
    beforeMount() {
        //document.title = this.album.name
    },
    methods: {
        ...mapActions("album", ["getSignleAlbum"]),
    },
    computed: {
        ...mapState({ album: (state) => state.album.single }),
        ...mapState({ isLoading: (state) => state.album.isLoading }),
    },
    components: {
        CardSong
    }
};
</script>
