<template>
    <div class="container">
        <div class=" items-center flex rounded-md p-3 mb-3 h-32">
            <div class="text-center m-auto">
                <h1 class="text-white font-thin text-3xl md:text-5xl">NEWS</h1>
                <p class="block text-white mt-2 font-thin"> local & diaspora news</p>
            </div>
        </div>
        <div class="grid grid-cols-12">
            <div class="col-span-12 lg:col-span-8 flex flex-col gap-4">
                <div v-for="(story, index) in stories.data">
                    <div class="text-white bg-nomad-900 rounded-md p-5 grid grid gap-4 items-center hover:bg-nomad-800" v-if="index == 0">
                        <div class="" v-if="story.image">
                            <Link :href="'/story/' + story.id"><img :src="story.image" :alt="story.title" class="w-full bg-cover  rounded-md " /></Link>
                        </div>
                        <div class=" flex flex-col gap-3 ">
                            <div><span class="uppercase text-nomad-100 text-sm">{{ story.source.name }}</span></div>
                            <h3 class="text-xl lg:text-3xl font-semibold">
                                <Link :href="'/story/' + story.id"><span v-html="story.title"></span></Link>
                            </h3>
                            <div class="text-md text-nomad-50"><span class="hidden lg:inline-block	">{{ story.author }}
                                    |</span> <span>{{ story.published_at }}</span></div>
                        </div>
                    </div>
                    <div class="text-white bg-nomad-900 rounded-md p-5 grid grid-cols-12 gap-4 items-center hover:bg-nomad-800" v-else >
                        <div class="col-span-8 flex flex-col gap-3 ">
                            <div><span class="uppercase text-nomad-100 text-sm">{{ story.source.name }}</span></div>
                            <h3 class="text-md lg:text-xl font-semibold">
                                <Link :href="'/story/' + story.id">{{ story.title }}</Link>
                            </h3>
                            <div class="text-md text-nomad-50"><span class="hidden lg:inline-block" v-if="story.author">{{ story.author }}
                                    |</span> <span>{{ story.published_at }}</span></div>
                        </div>
                        <div class="col-span-4" v-if="story.image">
                            <Link :href="'/story/' + story.id"><img :src="story.image" :alt="story.title" class="w-full max-h-52 bg-cover  rounded-md " /></Link>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-span-12 lg:col-span-4">
                <adsense /> 
            </div>
        </div>
    </div>
</template>
<script setup>
import { Link, usePage, router } from "@inertiajs/vue3";
const { stories } = usePage().props;
</script>
<script>
import { defineAsyncComponent } from 'vue'
export default {
    components: {
        'adsense': defineAsyncComponent(() => import("@/components/Adsense.vue")),
    }
}
</script>