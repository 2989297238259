<template>
    <div class="w-full">
        <adsense />
        <div
            class="w-full grid grid-cols-1 md:grid-cols-6 gap-6"
            v-if="event && !isLoading"
        >
            <div class="md:col-span-2">
                <img
                    :src="event.poster"
                    :alt="event.name"
                    class="rounded-lg w-full transition-all ease-in-out"
                    loading="lazy"
                />
            </div>
            <div class="md:col-span-4 gap-3 flex flex-col">
                <h1 class="text-nomad-100 font-bold text-3xl">
                    {{ event.name }}
                </h1>
                <p
                    class="text-nomad-200 font-thin text-md md:text-lg lg:text-xl"
                >
                    {{ event.description }}
                </p>
                <a
                    href="#"
                    @click.prevent="openMap"
                    class="flex items-center rounded-md overflow-hidden"
                >
                    <span
                        class="icon-navigation text-nomad-accent h-full px-3 py-3"
                    ></span>
                    <span class="text-nomad-300 px-3 font-thin"
                        ><strong class="block bold">{{ event.venue }}</strong
                        >{{ event.street }} {{ event.city }}
                        {{ event.postal_code }} {{ event.country.name }}</span
                    >
                </a>
                <div class="flex items-center rounded-md overflow-hidden">
                    <span
                        class="icon-dollar-sign text-nomad-accent h-full px-3 py-3"
                    ></span>
                    <span class="text-nomad-300 px-3">{{ event.price }}</span>
                </div>
                <div class="flex items-center rounded-md overflow-hidden">
                    <span
                        class="icon-calendar text-nomad-accent h-full px-3 py-3"
                    ></span>
                    <span class="text-nomad-300 px-3">{{ event.date_at_formated }}</span>
                </div>
                <div
                    class="flex items-center rounded-md overflow-hidden"
                    v-if="event.artists.length > 0"
                >
                    <span
                        class="icon-users text-nomad-accent h-full px-3 py-3"
                    ></span>
                    <span class="text-nomad-300 px-3"
                        ><featured-artist :artists="event.artists"
                    /></span>
                </div>
                <a
                    :href="event.ticket_url"
                    v-if="event.ticket_url"
                    target="_blank"
                    class="flex items-center rounded-lg overflow-hidden"
                >
                    <span
                        class="icon-tag bg-nomad-accent h-full px-3 py-3"
                    ></span>
                    <span class="text-nomad-accent px-3 uppercase"
                        >Buy ticket</span
                    >
                </a>
            </div>
        </div>
    </div>
</template>
<script setup>
defineProps({ event: Object });
</script>
<script>
import { mapState, mapActions } from "vuex";
import { usePage } from "@inertiajs/vue3";
import { defineAsyncComponent } from 'vue'
const { join } = defineAsyncComponent(() => import('lodash'))


export default {
    methods: {
        ...mapActions("event", ["getSignleEvent"]),
        openMap() {
            let address = [
                this.event.street,
                this.event.city,
                this.event.country.name,
                this.event.postal_code,
            ];

            address = join(address, "+");

            window.open("http://maps.google.com/?daddr=" + address, "_blank");
        },
    },
    created() {
        // const id = usePage().props.query.id;
        // console.og(id)
        // this.getSignleEvent(id);
    },
    computed: {
        ...mapState({ event: (state) => state.event.single }),
        ...mapState({ isLoading: (state) => state.event.isLoading }),
    },

    components: {
        'featured-artist': defineAsyncComponent(() => import("@/components/FeaturedArtist.vue")),
        'adsense' : defineAsyncComponent(() => import("@/components/Adsense.vue")),
    },
    created() {
        document.title = this.event.name + " Event"
    },
};
</script>
