<template>
    <Container>
        <div class="w-full flex my-4 gap-3">
            <Link
                href="/backstage/songs/create"
                class="text-nomad-accent rounded-md py-2 px-3 bg-nomad-700 hover:bg-nomad-600"
                >Create</Link
            >
            <Link
                href="/backstage"
                class="text-white rounded-md py-2 px-3 bg-nomad-900 hover:bg-nomad-800"
                >Cancel</Link
            >
        </div>
        <div class="w-full my-4">
            <input
                type="text"
                placeholder="search songs"
                class="rounded-md border-0 outline-0"
            />
        </div>
        <div class="w-full">
            <ul v-if="songs && songs.data" class="grid gap-3">
                <li
                    v-for="song in songs.data"
                    :key="song.id"
                    class="p-3 bg-nomad-900 rounded-3xl text-white flex"
                >
                    <Link :href="'/backstage/songs/' + song.id + '/edit'"
                        ><h2 class="text-xl text-nomad-200">{{ song.name }}</h2> <span class="text-nomad-600">{{ song.artist.name }}</span></Link
                    >
                    <div v-if="song.meta && song.meta.status" class="w-48">
                        <span class="icon-check text-nomad-accent" v-if="song.meta.status == 'repeat'"></span>
                        <div v-else-if="song.meta.status == 'fail'">
                            <span class="icon-x text-nomad-red" ></span>
                            <button @click.prevent="retryDownload(song.id)" class="icon-repeat text-nomad-accent rounded-full bg-nomad-400"></button>
                        </div>
                        <span class="icon-git-pull-request text-nomad-red" v-else-if="song.meta.status == '' || !song.meta.status"></span>
                        <span v-else-if="song.meta.retry" class="icon-hard-drive text-nomad-accent"></span>
                        <span class="icon-check text-nomad-green" v-else></span>
                    </div>
                </li>
            </ul>
        </div>
    </Container>
</template>
<script setup>
import Container from "@/Pages/Backstage/Container.vue";
import { Link, usePage } from "@inertiajs/vue3";
import axios from 'axios';
const { songs } = usePage().props


function retryDownload(id) {
    axios.post('/api/backstage/retry', {id: id})
}
</script>