<template>
    <div class="w-full text-center text-nomad-400 font-thin my-10 mb-60">
        <p>&copy; 2023 Nomad Lyrics</p>
        <p>
            built with
            <a
                href="https://www.buymeacoffee.com/kaym.dev"
                class="icon-heart hover:text-nomad-accent text-sm hover:scale-125 transition-all text-nomad-400"
                target="_blank"
                aria-label="buy me a coffee"
            ></a> by <a href="https://nexainteractives.com" target="_blank" class="font-bold">Nexa Interactives</a>
        </p>
    </div>
</template>
