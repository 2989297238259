<template>
    <div class="bg-nomad-800 max-w-lg my-3 rounded-md p-3 flex flex-col gap-3">
        <h1 class="text-nomad-accent uppercase font-light">Youtube Urls</h1>
        <div class=" text-sm">
            <input type="text" class="rounded-md" placeholder="youtube" v-model="youtube" />
            <div class="flex gap-3">
                <button class="rounded-md bg-nomad-900 p-3 text-nomad-accent" @click="fetchYoutube">fetch
                    content</button>
            </div>
        </div>
        <div class=" text-sm" v-if="message">{{ message }}</div>
        <div class=" text-sm">
            <table class="table-auto min-w-full divide-y divide-gray-200 dark:divide-neutral-700">
                <thead>
                    <tr class="text-white">
                    <th class="px-2 py-3 text-start uppercase">resolution</th>
                    <th class="px-2 py-3 text-start uppercase">video</th>
                    <th class="px-2 py-3 text-start uppercase">audio</th>
                    <th class="px-2 py-3 text-start uppercase">extension</th>
                    <th class="px-2 py-3 text-start uppercase">download</th>
                </tr>
                </thead>
                <tbody v-if="contents && contents.formats">
                    <tr v-for="video in contents.formats" v-key="video.format_id" class="text-white">
                        <td class="px-2 py-4 whitespace-nowrap">{{ video.resolution }}</td>
                        <td class="px-2 py-4 whitespace-nowrap">{{ video.video_ext }}</td>
                        <td class="px-2 py-4 whitespace-nowrap">{{ video.audio_ext }}</td>
                        <td class="px-2 py-4 whitespace-nowrap">{{ video.ext }}</td>
                        <td class="px-2 py-4 whitespace-nowrap"><a :href="video.url" target="_blank" class="rounded bg-nomad-accent px-3 py-2" v-if="video.url">download</a></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>
<script>
import axios from 'axios';

export default {
    data() {
        return {
            youtube: null,
            contents: null,
            message: null
        }
    },
    methods: {
        fetchYoutube() {
            this.message = null
            axios.get('/api/backstage/youtube-json/' + this.youtube)
                .then((response) => {
                    if(response.data.message) {
                        this.message = response.data.message
                    } else {
                        this.contents = response.data
                    }
                })
        }
    }
}
</script>